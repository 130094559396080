import React, { useState } from 'react';

export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-2'
            onClick={handleMenuClick}
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a className='navbar-brand page-scroll' href='/'>
            <img style={{ height: 50, overflow: 'hidden', }} align="left" src="img/logo.jpeg" alt='best ro-repair service' />
            <p style={{ display: "inline-block", fontSize: '14px', marginBottom: '2px', marginTop: '6px' }}> H2O Home Appliance Services</p>
            <p style={{ fontSize: '9px' }}>&nbsp; Fixing the Impossible, Just for You!</p>
          </a>{' '}
        </div>

        <div
          className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='/#contact' className='page-scroll' onClick={handleLinkClick}>
                Contact
              </a>
            </li>
            {/*<li>
              <a href='/#features' className='page-scroll'>
                What We Do
              </a>
            </li> */}
            <li>
              <a href='/#about' className='page-scroll' onClick={handleLinkClick}>
                About
              </a>
            </li>
            <li>
              <a href='/#pricetag' className='page-scroll' onClick={handleLinkClick}>
                Pricing
              </a>
            </li>
            <li>
              <a href='/#services' className='page-scroll' onClick={handleLinkClick}>
                Services
              </a>
            </li>
            <li>
              <a href='/#testimonials' className='page-scroll' onClick={handleLinkClick}>
                Testimonials
              </a>
            </li>
            
            <li>

              <a className="btn-right" href="tel:9031062011" style={{ color: '#ef630b' }}><i class="fa fa-phone" style={{fontSize :"18px"}}> 1800-420-1440</i></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
